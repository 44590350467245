// @ts-expect-error untyped file
import { handleActions } from 'flux-actions';
import { UPDATE_VISITOR_IDENTIFICATION_ENABLED } from '../constants/ActionTypes'; // @ts-expect-error untyped file

const initialState = false;
export default handleActions({
  [UPDATE_VISITOR_IDENTIFICATION_ENABLED](state, action) {
    const {
      enabled
    } = action.payload;
    return enabled;
  }

}, initialState);