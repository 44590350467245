export const hasRequiredFeatures = window =>
/* Window */
{
  if (typeof window.requestAnimationFrame !== 'function') {
    return false;
  }

  if (typeof window.WeakMap !== 'function') {
    return false;
  }

  if (typeof window.URLSearchParams !== 'function') {
    return false;
  }

  if (typeof window.Promise.all !== 'function') {
    return false;
  }

  if (typeof window.Promise.resolve !== 'function') {
    return false;
  }

  return true;
};