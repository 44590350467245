import curry from 'transmute/curry';
import updateIn from 'transmute/updateIn';
import { FRIENDLY_NAMES } from '../constants/keyPaths';
export const mergeFriendlyNames = curry((newFriendlyNames, threadHistory) => {
  return updateIn(FRIENDLY_NAMES, existingFriendlyNames => {
    if (!newFriendlyNames || !newFriendlyNames.length) {
      return existingFriendlyNames;
    }

    return [...new Set(existingFriendlyNames.concat(newFriendlyNames).map(name => JSON.stringify(name)))].map(stringifiedName => JSON.parse(stringifiedName));
  }, threadHistory);
});